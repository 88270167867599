<template>
    <div class="inner-container">
        <div class="section-title budget"><span>Бюджет</span></div>
        <div class="section-subtitle">Сводные отчеты <span><budget-reports-list :curFormSelect="'/adjust-report'"/></span></div>
        <b-tabs v-model="tabIndx" v-if="listReg !== null">
            <b-tab title="Баланс">
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadBalance">Сводный баланс</b-dropdown-item>
                        <b-dropdown-item v-if="selecteds_ === 1" @click="downloadBalanceWithStructure">Изменение бюджета</b-dropdown-item>
                        <b-dropdown-item v-if="selecteds_ === 1" @click="downloadBalanceDetailedReport">Развернутый баланс</b-dropdown-item>
                    </b-dropdown>
                </div>
                <b-progress variant="success" v-show="progress<100" height="10px" :value="progress" striped
                            animated></b-progress>
                <template>
                    <balance-form ref="balanceform"
                                  :year="year"
                                  :reg="region"
                                  :data-type="dataType"
                                  @chgData="chgData"
                                  @selectedsChanged="handleSelectedsChanged"
                    ></balance-form>
                </template>
            </b-tab>
            <b-tab title="Расходы">
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadExpenditure">Расходы</b-dropdown-item>
                        <b-dropdown-item @click="downloadExpenditures">Расходы(все отчеты)</b-dropdown-item>
                        <b-dropdown-item @click="downloadBudgetChange">Изменение бюджета</b-dropdown-item>
                    </b-dropdown>
                </div>
                <b-progress variant="success" v-show="progress<100" height="10px" :value="progress" striped
                            animated></b-progress>
                <template>
                    <cost-reports ref="costreports"
                                  :year="year"
                                  :obl="obl"
                                  :reg="region"
                                  :variant="variant"
                                  :data-type="dataType"
                                  :list-reg="listReg"
                                  @chgData="chgData"
                    ></cost-reports>
                </template>
            </b-tab>
            <b-tab title="Доходы" tabindex="">
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadInExcel">Доходная часть бюджета</b-dropdown-item>
                    </b-dropdown>
                </div>

                <template>
                    <income-form ref="incomeform"
                                 mode="report"
                                 :year="parseInt(year)"
                                 :obl="obl"
                                 :reg="region"
                                 :variant="variant"
                                 :list-reg="listReg"
                                 :data-type="dataType"
                                 :list-variants-inc="listVariantsInc"
                                 @chgData="chgData"
                    ></income-form>
                </template>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '../../../services/store';
import { Ax } from '@/utils';
import CostReports from '@/modules/budget/CostReports';
import BalanceForm from '@/modules/budget/BalanceForm';
import IncomeForm from '@/modules/budget/IncomeForm';
import { CUR_YEAR } from '../../budget-request/components/js/budgetCurYear';
import BudgetReportsList from '@/modules/budget/components/budget-reports-list.vue';
import {loadVariants} from '@/modules/budget/budgetVariantService';

export default {
    name: 'AdjustForm',
    components: { CostReports, BalanceForm, IncomeForm, BudgetReportsList },
    data() {
        return {
            year: null,
            obl: null, // '45'
            region: null, // '450101',
            variant: 'f1340fa7-e90d-42a2-92ae-a6055dc1d278',
            tabIndx: 0,
            listReg: null,
            progress: 0,
            dataType: 4,
            selectedAbp: null,
            selectedPrg: null,
            selectedPpr: null,
            curVariant: null,
            state: null,
            user: null,
            selecteds_: 1,
            listVariantsInc: []
        };
    },

    async mounted() {
        this.user = store.state.user;
        this.state = store.state;
        this.year = (new Date().getFullYear() + 1).toString();
        await this.getObl();
    },

    methods: {
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        this.loadRegions();
                    });
                await loadVariants().then(data => {
                    this.listVariantsInc = data;
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },
        async loadRegions() {
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.user.sub);
                if (response.status === 200) {
                    this.listReg = await response.json();
                    this.region = this.listReg[0].code;
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов

        chgData(data) {
            try {
                const curHeader = data;
                this.header = {
                    year: curHeader.year,
                    curYear: curHeader.curYear,
                    dataType: 4,
                    region: curHeader.region,
                    version: curHeader.version,
                    variant: curHeader.variant,
                    abp: curHeader.abp,
                    prg: curHeader.prg,
                    ppr: curHeader.ppr,
                    view: curHeader.view,
                    develop: curHeader.develop,
                    spf: curHeader.spf
                };
            } catch (error) {
                this.makeToast('danger', 'Предупреждение', 'Ошибка обновления параметров ' + error.toString());
            }
        }, // обновление параметров

        infoCostForm(info_id) {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-info/' + info_id, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let file = '';
                    if (info_id === 1) {
                        file = 'РП Формирование расходной части';
                    }
                    if (info_id === 2) {
                        file = 'РП Формирование доходной части';
                    }
                    link.setAttribute('download', file + '.pdf');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        handleSelectedsChanged(selecteds) {
            this.selecteds_ = selecteds;
        },
        // формирование отчета
        downloadInExcel() {
            const that = this;
            Ax(
                {
                    url: '/api-py/in-form-download/' + that.region + '/' + that.header.variant + '/' + that.header.year + '/' + that.dataType, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Доходная часть бюджета.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета balance-agr-download
        downloadBalance() {
            const that = this;
            this.progress = 15;
            Ax(
                {
                    url: '/api-py/balance-agr-download/' + JSON.stringify(that.header), //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    this.progress = 45;
                    link.setAttribute('download', 'Сводный баланс бюджета в агрегированной форме.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.progress = 100;
                },
                (error) => {
                    that.error = error;
                    this.progress = 0;
                }
            );
        },
        downloadBalanceWithStructure() {
            const that = this;
            this.progress = 15;
            const year = new Date().getFullYear();
            const day = this.padLeadingZeros(new Date().getDate(), 2);
            const month = this.padLeadingZeros(new Date().getMonth() + 1, 2);
            this.curDate = year + '_' + month + '_' + day;
            Ax(
                {
                    url: '/api-py/balance_structure_download/' + JSON.stringify(that.header), //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    this.progress = 45;
                    link.setAttribute('download', 'Изменение_бюджета_баланс_' + this.curDate + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.progress = 100;
                },
                (error) => {
                    that.error = error;
                    this.progress = 0;
                }
            );
        },
        downloadBalanceDetailedReport() {
            const that = this;
            this.progress = 15;
            const year = new Date().getFullYear();
            const day = this.padLeadingZeros(new Date().getDate(), 2);
            const month = this.padLeadingZeros(new Date().getMonth() + 1, 2);
            this.curDate = year + '_' + month + '_' + day;
            Ax(
                {
                    url: '/api-py/balance_detailed_report_download/' +JSON.stringify(that.header), //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url =window.URL.createObjectURL(new Blob([data]));
                    const link =document.createElement('a');
                    link.href = url;
                    this.progress = 45;
                    link.setAttribute('download', 'Развернутый_баланс_' + this.curDate + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.progress = 100;
                },
                (error) => {
                    that.error = error;
                    this.progress = 0;
                }
            );
        },
        // формирование отчета Expenditure-download
        downloadExpenditure() {
            const that = this;
            this.progress = 15;
            Ax(
                {
                    url: '/api-py/expenditure-download/' + JSON.stringify(that.header), //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    this.progress = 40;
                    link.setAttribute('download', 'Расходная часть бюджета.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.progress = 100;
                },
                (error) => {
                    that.error = error;
                    this.progress = 0;
                }
            );
        },
        // формирование отчета Expenditures-download
        downloadExpenditures() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            this.progress = 15;
            Ax(
                {
                    url: '/api-py/expenditures-download/' + JSON.stringify(that.header), //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    this.progress = 40;
                    link.setAttribute('download', 'Расходная часть бюджета (все отчеты).xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.progress = 100;
                },
                (error) => {
                    that.error = error;
                    this.progress = 0;
                }
            );
        },
        // формирование отчета budget_change
        downloadBudgetChange() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            this.progress = 25;
            const year = new Date().getFullYear();
            const day = this.padLeadingZeros(new Date().getDate(), 2);
            const month = this.padLeadingZeros(new Date().getMonth() + 1, 2);
            this.curDate = year + '_' + month + '_' + day;
            Ax(
                {
                    url: '/api-py/budget_change/' + JSON.stringify(that.header), //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    this.progress = 40;
                    link.setAttribute('download', 'Изменение_бюджета_' + this.curDate + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.progress = 100;
                },
                (error) => {
                    that.error = error;
                    this.progress = 0;
                }
            );
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        padLeadingZeros(num, size) {    //
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        } // добавляет 0-ли перед num до size-значного размера
    },
    computed: {
        userId() {
            return this.$store.getters.user_uuid;
        }
    },
    watch: {
        userId: async function (newVal, oldVal) {
            if (newVal!= oldVal) {
                await this.getObl();
            }
        }
    }

};
</script>
<style>
.is-hidden {
    display: none !important;
}
</style>